// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-project-style-1-js": () => import("./../../../src/pages/ProjectStyle1.js" /* webpackChunkName: "component---src-pages-project-style-1-js" */),
  "component---src-pages-projects-1-js": () => import("./../../../src/pages/projects-1.js" /* webpackChunkName: "component---src-pages-projects-1-js" */),
  "component---src-templates-feature-details-template-js": () => import("./../../../src/templates/feature-details-template.js" /* webpackChunkName: "component---src-templates-feature-details-template-js" */),
  "component---src-templates-project-details-template-js": () => import("./../../../src/templates/project-details-template.js" /* webpackChunkName: "component---src-templates-project-details-template-js" */),
  "component---src-templates-service-details-template-js": () => import("./../../../src/templates/service-details-template.js" /* webpackChunkName: "component---src-templates-service-details-template-js" */)
}

